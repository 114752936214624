import React, { useState } from 'react'
import { Select } from 'antd';
import { Cookies } from "react-cookie";
import { encryptData } from '../../../services/EncryptData.js';
import { getSocket } from '../../../services/Socket.js';

export default function MyDropdown({ data, reloadSections, reloadQuestions }) {

    const { question } = data;
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const isReviewedChecked = data?.is_sealed;
    const disabledStyle = { cursor: "not-allowed" };

    const onChange = (value) => {
        const answer = encryptData({ token: token, id: data?.id, response_text: question?.options[value] });
        socket.emit('updateQuestion', answer);

        // Update section and question data so conditional section and question can render
        reloadSections(false);
        reloadQuestions(false);
    }

    const createOptions = (options) => {
        return options?.map((item, index) => { return { value: index, label: item } });
    }

    return (
        <div>
            {data?.is_show == 1 && <div className='mt-6'>
                <div className='flex items-start gap-2 mb-2'>{question?.is_required === 1 && <p className='text-red-600 text-lg'>*</p>}<p>{question?.field_label}</p></div>
                <Select
                    className='w-full h-[49px]'
                    disabled={isReviewedChecked}
                    style={isReviewedChecked ? disabledStyle : {}}
                    placeholder='Select option'
                    options={createOptions(question?.options)}
                    defaultValue={data?.response_text}
                    onChange={onChange}
                />
            </div >}
        </div>
    )
}
