import React, { useEffect, useState } from 'react';
import { Collapse, Spin } from 'antd';
import { GetFaqListApi } from '../../../api/request/communication/faq';
const Faq = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getFaqList = async () => {
        try {
            setIsLoading(true);
            const { data } = await GetFaqListApi();
            if (data?.status) {
                setData(data?.data?.map((item, index) => {
                    return {
                        key: index,
                        label: <div className='font-semibold text-base'>{item.question}</div>,
                        children: <div className='text-sm'>{item.answer}</div>,
                    }
                }));
                setIsLoading(false);
            }
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getFaqList();
    }, []);

    return (
        <>
            {isLoading ?
                <div className='text-center'> <Spin /> </div>
                :
                <Collapse  expandIcon={({ isActive }) => isActive ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mt-2">
                    <path fill-rule="evenodd" d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mt-2">
                        <path fill-rule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
                    </svg>}
                    expandIconPosition='end'
                    items={data} />}
        </>
    );
}
export default Faq;