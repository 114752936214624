import ChangePassword from '../pages/auth/ChangePassword.jsx';
import Forgot from '../pages/auth/Forgot.jsx';
import Login from '../pages/auth/Login.jsx';
import Otp from '../pages/auth/Otp.jsx';
import Register from '../pages/auth/Register.jsx';
import SignUp from '../pages/auth/SignUp.jsx';
import Dashboard from '../pages/dashboard/Dashboard.jsx';
import Organizer from '../pages/organizer/Organizer.jsx';
import Documents from '../pages/documents/Documents.jsx';
import Trash from '../pages/documents/components/Trash.jsx';
import Communication from '../pages/communication/Communication.jsx';
import Referrals from '../pages/referrals/Referrals.jsx';
import Settings from '../pages/settings/Settings.jsx';
import TFAuthentication from '../pages/auth/TFAuthenticator.jsx';
import CreateOrganizer from '../pages/organizer/components/CreateOrganizer.jsx';
import QuestionAnwserFlow from '../pages/organizer/components/QuestionAnswerFlow.jsx';
import FillingPdf from '../pages/organizer/components/FillingPdf.jsx';
import PaymentVerification from '../services/payments/stripe/PaymentVerification.jsx';
import FillingPdfForAndroid from '../pages/organizer/components/FillingPdfForAndroid.jsx';
import SignPdfForAndroid from '../pages/organizer/components/SignPdfForAndroid.jsx';

export const initialRoutes = () => {
    const routes = [
        // ============ Auth ===============
        { path: "/login", type: "auth", component: Login, title: 'Login' },
        { path: "/signup", type: "auth", component: SignUp, title: 'SignUp' },
        { path: "/register", type: "auth", component: Register, title: 'Register' },
        { path: "/otp", type: "auth", component: Otp, title: 'Otp' },
        { path: "/authentication", type: "auth", component: TFAuthentication, title: 'Authentication' },
        { path: "/forgot_password", type: "auth", component: Forgot, title: 'ForgotPassword' },
        { path: "/change_password/:token", type: "auth", component: ChangePassword, title: 'ChangePassword' },
        // ============ Public =============
        { path: "/fill_organizer_data/:id/:token", type: "public", component: FillingPdfForAndroid, title: '' },
        { path: "/sign_pdf/:id/:type/:token", type: "public", component: SignPdfForAndroid, title: '' },
        // ============ Privet ==============
        { path: "/dashboard", type: "private", component: Dashboard, title: "Dashboard" },
        { path: "/", type: "private", component: Dashboard, title: "Dashboard" },
        { path: "/organizer", type: "private", component: Organizer, title: "Organizer" },
        { path: "/fill_organizer/:id", type: "private", component: FillingPdf, title: "" },
        { path: "/start_organizer", type: "private", component: CreateOrganizer, title: "Start Organizer" },
        { path: "/organizer/:id", type: "private", component: QuestionAnwserFlow, title: "Organizer" },
        { path: "/documents", type: "private", component: Documents, title: "Documents" },
        { path: "/documents_trash", type: "private", component: Trash, title: "Trash" },
        { path: "/communication", type: "private", component: Communication, title: "Communication" },
        { path: "/referrals", type: "private", component: Referrals, title: "Referrals" },
        { path: "/settings", type: "private", component: Settings, title: "Settings" },

        { path: "payment_verification/:id", type: "private", component: PaymentVerification, title: "Payment Verification" },
    ]
    return routes;
}