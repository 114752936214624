import React from 'react';
import { Input } from 'antd';
import { Cookies } from "react-cookie";
import { encryptData } from '../../../services/EncryptData.js';
import { getSocket } from '../../../services/Socket.js';

export default function MyNumber({ data }) {

    const { question } = data;
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const isReviewedChecked = data?.is_sealed;
    const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };

    const onBlur = (event) => {
        const answer = encryptData({ token: token, id: data?.id, response_text: event?.target?.value });
        socket.emit('updateQuestion', answer);
    }

    return (
        <div>
            {data?.is_show == 1 && <div className='mt-6'>
                <div className='flex items-start gap-2 mb-2'>{question?.is_required === 1 && <p className='text-red-600 text-lg'>*</p>}<p>{question?.field_label}</p></div>
                <Input
                    className='py-3 ps-4'
                    disabled={isReviewedChecked}
                    style={isReviewedChecked ? disabledStyle : {}}
                    size='large'
                    type='number'
                    placeholder='Number'
                    defaultValue={data?.response_text}
                    onBlur={onBlur}
                />
            </div>}
        </div>
    )
}