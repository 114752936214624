import { Avatar, Button, Drawer } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GetNotificationApi, DeleteNotificationApi } from '../../../api/request/notification.js';
import Notifications from './components/Notifications';
import toast from 'react-hot-toast';

export default function Header({ collapsed, setCollapsed, isDrawerOpen, setIsDrawerOpen, title }) {

  const userDetails = useSelector((state) => state.user.userDetails);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotification, setIsNotification] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.setAttribute('data-theme', 'dark'); // Apply dark theme
    } else {
      root.removeAttribute('data-theme'); // Remove dark theme
    }
  }, [isDarkMode]);

  const handleThemeToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  const getNotifications = async () => {
    try {
      const { data } = await GetNotificationApi();
      if (data?.status) {
        setNotifications(data?.data);
      }
    } catch (error) { }
  }

  useEffect(() => {
    getNotifications();
  }, [userDetails?.id]);

  
  const getNotificationIds = async () => {
    return notifications?.map(obj => obj.id)?.filter(id => id !== undefined && id !== null)?.join(',');
  }

  const clearNotifications = async () => {
    try {
      let params = { ids: await getNotificationIds() }
      const { data } = await DeleteNotificationApi(params);
      if (data.status) {
        setNotifications([]);
        toast.success(data.message);
        setIsNotification(false);
      }
    } catch (error) { }
  };

  const openNotificationModal = () => {
    getNotifications();
    setIsNotification(true);
  }

  return (
    <div className='border-b-2 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <svg onClick={() => setCollapsed(!collapsed)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 cursor-pointer hidden lg:block">
            <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
          </svg>
          <svg onClick={() => setIsDrawerOpen(!isDrawerOpen)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 cursor-pointer lg:hidden">
            <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
          </svg>
          <p className="text-xl font-bold capitalize hidden md:block">{title}</p>
          {/* <Button type="primary" onClick={handleThemeToggle}>
            Toggle Dark Mode
          </Button> */}
        </div>
        <div className='flex items-center gap-3 divide-x-2'>
          <div>
            <div className='border-2 size-10 rounded-full flex items-center justify-center cursor-pointer' onClick={openNotificationModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="var(--text-color)" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
              </svg>
            </div>
          </div>
          <div className='ps-3'>
            <div className='flex items-center gap-3 cursor-pointer'>
              <Avatar size='large' className='bg-primary uppercase' src={userDetails?.image}>{(userDetails?.first_name)?.charAt(0)}{(userDetails?.last_name)?.charAt(0)}</Avatar>
              <div>
                <p className='leading-5 capitalize'>{userDetails?.name}</p>
                <p className='text-sm text-secondaryLight1'>{userDetails?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --- Notification drawer --- */}
      <Drawer
        title={
          <div className="flex justify-between items-center">
            <p>Notifications</p>
            <p className="cursor-pointer" onClick={clearNotifications}>
              Clear all
            </p>
          </div>
        }
        onClose={() => setIsNotification(false)}
        width={600}
        open={isNotification}
      >
        <Notifications notifications={notifications} getNotification={getNotifications} />
      </Drawer>
    </div>
  )
}