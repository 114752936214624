import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VerifyStripePaymentApi } from '../../../api/request/payments/payments.js';
import Eye from '../../../assets/Eye.jsx';

export default function PaymentVerification() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [paymentData, setPaymentData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        handlePaymentVerification(id);
    }, [id]);

    const handlePaymentVerification = async (id) => {
        if (!id) { return; }
        setIsLoading(true);
        try {
            let params = { organizer_id: id };
            const { data } = await VerifyStripePaymentApi(params);
            if (data?.status) {
                setPaymentData(data?.data);
            }
            setPaymentData(data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(true);
        }
    }

    return (
        <div>
            {
                (!isLoading) ? (
                    <div>
                        {paymentData?.payment_status === 'paid' ? (
                            <div className='flex flex-col justify-center items-center gap-6 w-full mt-20'>
                                <img src='/assets/images/payment-success.gif' className='h-64' />
                                <p className='text-green-500 text-2xl font-semibold'>Thank You!</p>
                                <p className='text-secondaryLight1 text-lg'>Your payment done successfully!</p>
                                <p className='text-secondaryLight1 text-lg'>Payment Id: {paymentData?.payment_intent_data?.id || '--'}</p>
                                <p className='text-secondaryLight1 text-lg'>Amount: $ {paymentData?.payment_intent_data?.amount || 0}</p>
                                <p className='text-primary flex justify-center items-center cursor-pointer' onClick={() => window.open(paymentData?.invoice_path,"_blank")}>
                                    <Eye></Eye> &nbsp;&nbsp; View Invoice</p>
                                <Button type='primary' ghost size='large' onClick={() => navigate('/dashboard')}>Go to Dashboard</Button>
                            </div>
                        ) : (
                            <div className='flex flex-col justify-center items-center gap-6 w-full mt-24'>
                                <img src='/assets/images/payment-fail.gif' className='h-64' />
                                <p className='text-red-600 text-2xl font-semibold'>Unfortunately payment was failed!</p>
                                <p className='text-secondaryLight1 text-lg'>Please try again.</p>
                                <Button type='primary' ghost size='large' onClick={() => navigate('/dashboard')}>Retry</Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='flex flex-col items-center gap-2 mt-[20%]'>
                        <Spin />
                        <p className='text-secondaryLight1'>Verifying your payment...</p>
                    </div>
                )
            }
        </div>
    )
}