import React, { useEffect, useState } from 'react';
import './Organizer.scss';
import MySegmented from '../../components/segmented/Segmented';
import RadialBarChart from '../../components/charts/RadialBarChart';
import { useNavigate } from 'react-router-dom';
import { GetOrganizerListApi } from '../../api/request/organizer/organizer.js';
import { Spin } from 'antd';
import moment from 'moment/moment';

export default function Organizer() {

  const navigate = useNavigate();
  const primaryColor = (getComputedStyle(document.documentElement).getPropertyValue('--primary'))?.trim();
  const [segmentedValue, setSegmentedValue] = useState('Active Files');
  const [isLoading, setIsLoading] = useState(false);
  const [organizerList, setOrganizerList] = useState([]);

  const getFilterStatus = (type) => {
    switch (type) {
      case 'Active Files':
        return 'status_active';
      case 'Archive Files':
        return 'is_archive';
      case 'Unsubmitted':
        return 'unsubmit';
      default:
        return '';
    }
  }

  const getOrganizerList = async () => {
    try {
      setIsLoading(true);
      let params = { type: getFilterStatus(segmentedValue) };
      const { data } = await GetOrganizerListApi(params);
      if (data?.status) {
        setOrganizerList(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setOrganizerList([]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getOrganizerList();
  }, [segmentedValue]);

  return (
    <div className='organizer-container'>
      <div className='grid xl:grid-cols-2 gap-5'>
        <div className='tax-filing-bg border-2 rounded-2xl p-8 flex items-center'>
          <div className='md:w-1/2'>
            <p className='font-semibold text-2xl leading-9'>Securely upload your documents on our user-friendly <span className='text-primary'> Tax Filing</span> platform.</p>
          </div>
        </div>
        <div className='warehouse-bg border-2 rounded-2xl p-8 flex items-center'>
          <div className='md:w-1/2'>
            <p className='font-bold text-2xl'>Your project data warehouse</p>
            <p className='text-secondaryLight1 mt-4'>Add project data, create thematic pages, edit data, share information with team members</p>
            <button className='flex items-center justify-center gap-3 mt-4 bg-primary text-white uppercase font-medium px-6 py-2 rounded-lg' onClick={() => navigate('/start_organizer')}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-5">
                <path fill-rule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
              </svg>
              Start Organizer
            </button>
          </div>
        </div>
      </div>

      <div className='mt-8'>
        <MySegmented options={['Active Files', 'Archive Files', 'Unsubmitted']} value={segmentedValue} setValue={setSegmentedValue} />

        <div>
          {isLoading ? (
            <div className='flex flex-col items-center gap-2 mt-16'>
              <Spin />
              <p className='text-secondaryLight1'>Loading...</p>
            </div>
          ) : (
            <div className='my-6'>
              <div>
                {
                  organizerList?.length ? (
                    <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-5'>
                      {
                        organizerList?.map((item, index) => (
                          <div key={index} className='border-2 rounded-2xl py-4 ps-4 flex items-center justify-between cursor-pointer' onClick={() => navigate(`/organizer/${item?.id}`)}>
                            <div>
                              <p className='font-semibold text-lg'>{item?.organizer_type?.organizer_name}</p>
                              <p className='font-medium mt-3'><span className='text-secondaryLight1'>Last Updated : </span>{moment(item?.updated_at).format('ll')}</p>
                              {
                                item?.is_submit ? (
                                  <div className='flex items-center gap-2 mt-3'>
                                    <div className='size-3 rounded-full bg-primary'></div>
                                    <p className='font-medium capitalize'>{item?.status}</p>
                                  </div>
                                ) : (
                                  <button className='rounded-full bg-primary text-white text-sm font-semibold uppercase py-1 px-6 mt-3'>Pending</button>
                                )
                              }
                            </div>
                            <div>
                              <RadialBarChart values={[item?.completion_percentage]} labels={['Completed']} colors={[primaryColor]} value={true} height={200} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  ) : (
                    <p className='text-secondaryLight1 text-center mt-12'>No organizers found</p>
                  )
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}