import api from "../index";

export const RegisterApi = (data) => api.post('clientregister', data);
export const LoginApi = (data) => api.post('login', data);
export const GoogleLoginApi = (data) => api.post('google-login', data);
export const LoginOtpVerificationApi = (data) => api.post('emailverification', data);
export const ResendOtpApi = (data) => api.post('resendotp', data);
export const SendForgotPasswordLinkApi = (data) => api.post('sendforgotlink', data);
export const ChangePasswordApi = (data) => api.post('resetpassword', data);
export const LogoutApi = () => api.post('logout');
export const VerifyTFAuthenticationApi = (data) => api.post('verify2fa', data);
