import React, { useEffect, useState } from 'react';
import ModalComponent from '../../../modals/ModalComponent.jsx';
import moment from 'moment/moment.js';
import { MarkAsReadNotificationApi } from '../../../../api/request/notification.js';

export default function Notifications({ notifications, getNotification }) {
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationData, setNotificationData] = useState(notifications || []);

    const viewNotification = (notification) => {
        setSelectedNotification(notification);
        setIsOpen(true);
    }

    const markAsRead = async (id) => {
        try {
            const params = { notification_id: id };
            const { data } = await MarkAsReadNotificationApi(params);
            if (data?.status) {
                let updatedNotifications = notificationData?.map((item) => {
                    if (item?.id === id) {
                        return { ...item, is_read: true }
                    } else {
                        return item;
                    }
                })
                setNotificationData(updatedNotifications);
                getNotification();
            }
        } catch (error) { }
    }

    useEffect(() => {
        setNotificationData(notifications);
    }, [notifications]);

    return (
        <div>
            {
                notificationData?.length > 0 ? (
                    <div>
                        {
                            notificationData?.map((item, index) => (
                                <div className={`flex items-center gap-4 border rounded-lg p-5 cursor-pointer my-2  ${item?.is_read ? '' : 'bg-primaryLight'}`} key={index}
                                    onClick={() => {
                                        viewNotification(item);
                                        if (!item.is_reads) {
                                            markAsRead(item?.id);
                                        }
                                    }}>
                                    <div className="w-full">
                                        <div className="flex justify-between items-center w-full">
                                            <p className="font-semibold text-base line-clamp-1 w-2/3">{item?.title}</p>
                                            <p className="text-sm text-gray-400">{moment(item?.created_at).fromNow()}</p>
                                        </div>
                                        <p className="mt-1 text-gray-500 line-clamp-1">{item?.description}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div className='mt-12'>
                        <p className='text-gray-400 text-center text-lg'>No Notification</p>
                    </div>
                )
            }


            <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title='Notification' width={800}>
                <div className="flex justify-between items-center w-full">
                    <p className='font-semibold text-xl mb-4'>{selectedNotification?.title}</p>
                    <p className="text-sm text-gray-400 text-right mb-4" > {moment(selectedNotification?.createdDate).fromNow()}</p >
                </div>
                <p className="text-lg">{selectedNotification?.description}</p>

            </ModalComponent >
        </div >
    )
}
