import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetPdfDataApiForAndroid, SubmitClientSignatureApiForAndroid } from '../../../api/request/organizer/organizer';
import { Button, Spin } from 'antd';
import LoadableButton from '../../../components/buttons/LoadableButton';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument, rgb } from 'pdf-lib';
import moment from 'moment';
import toast from 'react-hot-toast';

export default function SignPdfForAndroid() {

  const { id, type, token } = useParams();
  const sigCanvas = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [organizer, setOrganizer] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSignLoading, setIsSignLoading] = useState(false);

  const getOrganizer = async () => {
    if (!id) { return; }
    try {
      setIsLoading(true);
      let apiHeader = { headers: { Authorization: `Bearer ${token}`, Accept: "application/json", "Content-Type": "application/json" } };
      const { data } = await GetPdfDataApiForAndroid({ organizer_id: id }, apiHeader);
      if (data?.status) {
        setOrganizer(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getOrganizer();
  }, [id]);

  const clearSign = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear(); // Clear the signature
    }
  }

  const openPreview = () => {
    if (type == 'authorization') {
      window.open(organizer?.auth_pdf?.url, "_blank", "noopener,noreferrer");
    }
    if (type == 'gst') {
      window.open(organizer?.gst_pdf?.url, "_blank", "noopener,noreferrer");
    }
    if (type == 'organizer') {
      window.open(organizer?.organizer_pdf?.url, "_blank", "noopener,noreferrer");
    }
  };

  const signAuthorizationPdf = async (organizer, timestamp) => {
    try {
      const existingPdfBytes = await fetch(organizer?.auth_pdf?.url).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
      const form = pdfDoc.getForm();
      const sign = sigCanvas.current.toDataURL('image/png')
      const signatureImageBytes = await fetch(sign).then((res) =>
        res.arrayBuffer()
      );

      const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
      const pages = pdfDoc.getPages();
      const lastPage = pages[pages.length - 1];

      // For adding signature date
      form.getTextField('Text47').setText(moment().format('YYYYMMDD'));
      form.flatten();

      lastPage.drawImage(signatureImage, { x: 50, y: 165, width: 100, height: 30 });
      lastPage.drawText(`${timestamp}`, {
        x: 160, // Position to the right of the signature
        y: 170,
        size: 8,
        color: rgb(0.177, 0.177, 0.177) // Black color
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });

      return blob;

    } catch (error) {
      toast.error('An error occurred while processing the authorization PDF.');
      setIsSignLoading(false);
      return false;
    }
  }

  const signGSTPdf = async (organizer, timestamp) => {
    try {
      const existingPdfBytes = await fetch(organizer?.gst_pdf?.url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
      const form = pdfDoc.getForm();
      const sign = sigCanvas.current.toDataURL('image/png')
      const signatureImageBytes = await fetch(sign).then((res) =>
        res.arrayBuffer()
      );
      const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
      const pages = pdfDoc.getPages();
      const secondLastPage = pages[pages.length - 2];

      // For adding signature date
      form.getTextField('form1[0].Page5[0].Step7[0].Date11[0].Date11YYYYMMDD_Comb[0]').setText(moment().format('YYYYMMDD')); // Date Your signature
      // Spouse
      // form.getTextField('form1[0].Page5[0].Step7[0].Date12[0].Date12YYYYMMDD_Comb[0]').setText(moment().format('YYYYMMDD')); // Date Spouse or common-law
      form.flatten();

      // your signature
      secondLastPage.drawImage(signatureImage, {
        x: 140,
        y: 405,
        width: 100,
        height: 30,
      });
      // your spouse signature
      // secondLastPage.drawImage(signatureImage, {
      //   x: 140,
      //   y: 365,
      //   width: 100,
      //   height: 30,
      // });
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return blob;
    } catch (error) {
      toast.error('An error occurred while processing the GST PDF.');
      setIsSignLoading(false);
    }
  }

  const signOrganizerPdf = async (organizer, timestamp) => {
    try {
      const existingPdfBytes = await fetch(organizer?.organizer_pdf?.url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
      const sign = sigCanvas.current.toDataURL('image/png')
      const signatureImageBytes = await fetch(sign).then((res) => res.arrayBuffer());
      const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
      const pages = pdfDoc.getPages();
      const lastPage = pages[pages.length - 2];
      const current = new Date();
      const timestamp = current.toLocaleString(); // Get current date and time
      const date = `${current.getFullYear()}/${current.getMonth()}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      lastPage.drawImage(signatureImage, { x: 100, y: 185, width: 100, height: 30, });
      lastPage.drawText(time, { x: 490, y: 161, size: 8, color: rgb(0.177, 0.177, 0.177) });
      lastPage.drawText(date, { x: 420, y: 161, size: 8, color: rgb(0.177, 0.177, 0.177) });
      lastPage.drawText(`Parth Dhaduk`, { x: 400, y: 190, size: 8, color: rgb(0.177, 0.177, 0.177) });
      lastPage.drawText(`${timestamp}`, { x: 160, y: 190, size: 8, color: rgb(0.177, 0.177, 0.177) });;
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return blob;
    } catch (error) {
      toast.error('An error occurred while processing the Organizer PDF.');
      setIsSignLoading(false);
      return false;
    }
  }

  const submitSignedDoc = async () => {

    if (sigCanvas.current && sigCanvas.current.isEmpty()) {
      toast.error('Please provide your signature before submitting.');
      return;
    }

    setIsSignLoading(true);

    try {
      const current = new Date();
      const signDate = `${current.getFullYear()}-${current.getMonth()}-${current.getDate()}`
      const signTime = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const timestamp = moment().format('YYYY/MM/DD, LTS')
      // Get current date and time
      const formData = new FormData();
      formData.append('organizer_id', organizer?.id);
      formData.append('signDate', signDate);
      formData.append('signTime', signTime);
      formData.append('type', type);

      /* ------- Auth PDF -------- */
      if (organizer?.auth_pdf?.url && type == "authorizatio") {
        const authPdf = await signAuthorizationPdf(organizer, timestamp);
        formData.append('file', new File([authPdf], 'filled_form.pdf', { type: 'application/pdf' }));
      }

      /* ------- GST PDF -------- */
      if (organizer?.gst_pdf?.url && type == "gst") {
        const gstPdf = await signGSTPdf(organizer, timestamp);
        formData.append('file', new File([gstPdf], 'filled_form.pdf', { type: 'application/pdf' }));
      }

      /* ------- Organizer PDF -------- */
      if (organizer?.organizer_pdf?.url && type == 'organizer') {
        const organizerPdf = await signOrganizerPdf(organizer, timestamp);
        formData.append('file', new File([organizerPdf], 'filled_form.pdf', { type: 'application/pdf' }));
      }

      let apiHeader = { headers: { Authorization: `Bearer ${token}`, Accept: "application/json", "Content-Type": "multipart/form-data" } };

      const { data } = await SubmitClientSignatureApiForAndroid(formData, apiHeader);
      if (data?.status) {
        toast.success(data?.message);
        clearSign();
        setIsSuccess(true);
      }
      setIsSignLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsSignLoading(false);
    }
  }

  return (
    <div className='w-full h-[100vh] flex justify-center items-center'>
      {
        isLoading ? (
          <div className='flex flex-col items-center gap-2'>
            <Spin />
            <p className='text-secondaryLight1'>Loading...</p>
          </div>
        ) : (
          <div>
            {
              isSuccess ? (
                <div className='flex flex-col justify-center items-center gap-6 w-full'>
                  <img src='/assets/images/payment-success.gif' className='h-64' />
                  <p className='text-green-500 text-2xl font-semibold'>Thank You!</p>
                  <p className='text-secondaryLight1 text-lg'>You successfully signed the document!</p>
                </div>
              ) : (
                <div>
                  <p className='text-primary text-3xl font-bold text-center mt-1 mb-4'>TaxTribe</p>
                  <div className='p-4 border-2 rounded-lg'>
                    <p className='mb-3 font-semibold text-lg'>Please sign below to proceed.</p>
                    <SignatureCanvas
                      penColor="black"
                      ref={sigCanvas}
                      minDistance={1}
                      canvasProps={{ width: 460, height: 200, className: 'border', }}
                    />
                    <div className='flex flex-wrap justify-between items-center mt-6'>
                      <Button type='primary' ghost className='text-base uppercase font-medium py-[17px] px-8' onClick={clearSign}>Clear</Button>
                      <div className='flex items-center gap-3'>
                        <Button type='primary' ghost className='text-base uppercase font-medium py-[17px] px-8' onClick={openPreview} >Preview</Button>
                        <LoadableButton
                          className='bg-primary text-sm text-white font-medium uppercase px-8 py-2 rounded-lg themeHover duration-500'
                          type="button"
                          lable='proceed'
                          isLoading={isSignLoading}
                          loadingLable='Signing...'
                          onClick={submitSignedDoc}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </div >
  )
}