import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { RegisterApi } from '../../api/request/auth.js';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import LoadableButton from '../../components/buttons/LoadableButton.jsx';
import { GetCountryListApi } from '../../api/request/countryDetail.js';
import GoogleLoginComponent from './components/GoogleLoginComponent.jsx';
const { Option } = Select;

const Register = () => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({ first_name: "", last_name: "", email: "", mobile_no: "", password: "", tnc: false });
    const [isLoading, setIsLoading] = useState(false);
    const [countryData, setCountryData] = useState([]);

    const getCountryList = async () => {
        try {
            const { data } = await GetCountryListApi();
            if (data?.status) {
                setCountryData(data?.data);
            }
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
        }
    }

    useEffect(() => {
        getCountryList();
    }, [])

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            delete values?.country;
            delete values?.tnc;
            let params = {
                ...values,
                login_type: 'api'
            }
            const { data } = await RegisterApi(params);
            if (data?.status) {
                toast.success(data?.message);
                form.resetFields();
                navigate('/login');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className='sm:min-h-screen flex justify-center items-center'>
                <Form autoComplete='off' className='sm:w-3/5 w-full' form={form} initialValues={initialValues} onFinish={handleSubmit}>
                    <p className='font-bold sm:text-5xl text-3xl text-secondary mb-5'>Sign up</p>
                    <p className="text-lg text-secondaryLight2 mb-8">Create your taxtribe account</p>

                    <div className='grid lg:grid-flow-col gap-4'>
                        <Form.Item name="first_name" className='mb-0' rules={[{ required: true, message: 'Please enter your first name!', },]}>
                            <Input placeholder='First name' size='large' />
                        </Form.Item>
                        <Form.Item name="last_name" className='mb-0' rules={[{ required: true, message: 'Please enter your last name!', },]}>
                            <Input placeholder='Last name' size='large' />
                        </Form.Item>
                    </div>
                    <div className="flex sm:flex-row flex-col sm:gap-4">
                        <Form.Item name="country_code" className='mb-0 mt-5 sm:min-w-[28%]' rules={[{ required: true, message: 'Please select country', },]}>
                            <Select placeholder='Country code' size="large"  >
                                {countryData?.map((countryCode, index) => (
                                    <Option key={index} value={countryCode?.phonecode}>
                                        <div className="flex items-center space-x-3">
                                            <div className='rounded-full bg-secondaryLight w-9 h-9 flex justify-center items-center'>
                                                <img src={countryCode?.image} alt="flag" className="w-7 h-7 object-contain rounded-full" />
                                            </div>
                                            <span className='text-base'>+ {countryCode?.phonecode}</span>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="mobile_no" className='mb-0 mt-5 w-full' rules={[
                            { required: true, message: 'Please input  mobile number!', },
                            {
                                pattern: /^\d{10}$/,
                                message: 'Mobile number should be 10 digits!',
                            },
                        ]}>
                            <Input size="large" type='Number' placeholder="Mobile No." />
                        </Form.Item>
                    </div>
                    <Form.Item name="email" className='mb-0 mt-5' rules={[
                        { required: true, type: 'email', message: 'Please enter your email address!' }
                    ]}>
                        <Input placeholder='Email address' size='large' />
                    </Form.Item>
                    <Form.Item name="password" className='mb-0 mt-5' rules={[
                        { required: true, message: 'Please enter your password!' },
                        {
                            pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                            message: 'Please enter strong password.',
                        },
                    ]}>
                        <Input.Password placeholder='Password' size='large' />
                    </Form.Item>
                    <Form.Item name="tnc" valuePropName="checked" className='mb-0 mt-3' rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions.!')), }]}>
                        <Checkbox>I agree all the<span className='text-primary '> Terms </span>and<span className='text-primary'> Privacy Policies</span></Checkbox>
                    </Form.Item>
                    <LoadableButton
                        className='bg-primary text-white uppercase text-base font-semibold w-full py-2 rounded-lg mt-7 mb-5'
                        type='submit'
                        lable='Create Account'
                        loadingLable='Createing Account...'
                        isLoading={isLoading}
                    />
                    <p className='text-center font-semibold mb-5'>Already have an account? <span className='underline text-primary cursor-pointer' onClick={() => navigate('/login')}> Login </span></p>
                    <div className="flex items-center mb-5">
                        <hr className="flex-grow" />
                        <span className="mx-2 text-secondaryLight2">OR CONTINUE WITH</span>
                        <hr className="flex-grow" />
                    </div>
                    <div className='flex justify-center items-center  text-secondary '>
                    <GoogleLoginComponent />
                        {/* <button type="button" className='bg-primaryLight rounded-lg flex items-center justify-center py-2 sm:w-1/3 w-full '><img src='assets/icons/apple.svg' alt='icon' className='me-2' />Apple</button> */}
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Register;