import React, { useState } from 'react';
import { Cookies } from "react-cookie";
import { encryptData } from '../../../services/EncryptData.js';
import { getSocket } from '../../../services/Socket.js';

export default function MyCheckBox({ data, reloadSections, reloadQuestions }) {

    const { question } = data;
    const cookies = new Cookies();
    const { token } = cookies.getAll();
    const socket = getSocket();
    const [selectedValues, setSelectedValues] = useState(JSON.parse(data?.response_text));
    const isReviewedChecked = data?.is_sealed;
    const disabledStyle = { cursor: "not-allowed", opacity: 0.6 };

    const updateArray = (array = [], newValue) => {
        const index = array?.findIndex(value => value === newValue);
        if (index !== -1) {
            return array?.filter(value => value !== newValue);
        } else {
            return [...array, newValue];
        }
    }

    const checkStringInArray = (value) => {
        return selectedValues?.includes(value);
    }

    const onChange = (value) => {
        setSelectedValues(prevSelectedValues => {
            const answer = encryptData({ token: token, id: data?.id, response_text: JSON.stringify(updateArray(prevSelectedValues || [], value) || []), isArray: true });
            socket.emit('updateQuestion', answer);

            // Update section and question data so conditional section and question can render
            reloadSections(false);
            reloadQuestions(false);

            return updateArray(prevSelectedValues || [], value);
        });
    }

    return (
        <div>
            {data?.is_show == 1 && <div className='mt-6'>
                <div className='flex items-start gap-2 mb-2'>{question?.is_required === 1 && <p className='text-red-600 text-lg'>*</p>}<p>{question?.field_label}</p></div>
                <div className='w-full flex flex-wrap gap-5'>
                    {
                        question?.options?.map((item, index) => (
                            <div
                                key={index}
                                disabled={isReviewedChecked}
                                style={isReviewedChecked ? disabledStyle : {}}
                                className={`py-3 px-12 font-medium border-2 rounded-md cursor-pointer duration-500 ${checkStringInArray(item) ? 'text-primary border-primary bg-primaryLight' : 'text-secondaryLight1'}`}
                                onClick={!isReviewedChecked ? () => onChange(item) : null}
                            >
                                {item}
                            </div>
                        ))
                    }
                </div>
            </div >}
        </div>
    )
}