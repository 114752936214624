import React, { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Spin } from 'antd';
import { SubmitOrganizerPdfApi, GetAuthorizationDataApi } from '../../../api/request/organizer/organizer';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';

export default function FillingPdf() {

    const { id } = useParams();
    const [loadingText, setLoadingText] = useState('Loading...');
    const navigate = useNavigate();

    function adjustString(string, desiredLength) {

        let inputString = string?.toString();

        // Ensure inputString is a string and desiredLength is a positive integer
        if (typeof inputString !== "string" || typeof desiredLength !== "number" || desiredLength <= 0) {
            throw new Error("Invalid arguments. Expected a string and a positive number.");
        }

        if (inputString.length === desiredLength) {
            return inputString; // If the string matches the length, return as is
        }

        if (inputString.length < desiredLength) {
            // Add "000" as a prefix
            let diff = desiredLength - inputString.length;
            return "000".repeat(Math.ceil(diff / 3)).slice(0, diff) + inputString;
        }

        if (inputString.length > desiredLength) {
            // Trim the string to match desired length
            return inputString.slice(0, desiredLength);
        }
    }

    const fillAuthorizationPdf = async () => {
        const existingPdfBytes = await fetch('/assets/authorization.pdf').then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
        const form = pdfDoc.getForm();

        let params = { organizer_id: id, type: 'authorization' };
        const { data } = await GetAuthorizationDataApi(params);

        form.getTextField('Text1').setText(data?.data?.['Taxpayer Name - First Name & Middle Name'] || '');
        form.getTextField('Text2').setText(data?.data?.['Taxpayer Name - Last Name'] || '');
        if (data?.data?.['Taxpayer Phone']) {
            form.getTextField('Text3').setText(adjustString(data?.data?.['Taxpayer Phone'], 10) || '');
        }
        if (data?.data?.['Taxpayer Home Phone']) {
            form.getTextField('Text4').setText(adjustString(data?.data?.['Taxpayer Home Phone'], 10) || '');
        }
        form.getTextField('Text5').setText(adjustString(data?.data?.['Social Insurance Number'], 9) || '');
        form.getTextField('Text10').setText((process.env.REACT_APP_BUSINESS_NUMBER).toString() || '');
        form.getTextField('Text46').setText(`${data?.data?.['Taxpayer Name - First Name & Middle Name'] || ''} ${data?.data?.['Taxpayer Name - Last Name'] || ''}`);

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        return blob;
    }

    const fillGSTPdf = async () => {
        try {
            const existingPdfBytes = await fetch('/assets/gst.pdf').then((res) => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
            const form = pdfDoc.getForm();

            let params = { organizer_id: id, type: 'gst' };
            const { data } = await GetAuthorizationDataApi(params);
            let gstData = data?.data;

            //*--------- Step 1 – Your information ---------*//
            if (gstData['Social insurance number (SIN)']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].Step1_SIN_Comb_Top[0].Step1_SIN_Comb[0]').setText(gstData['Social insurance number (SIN)']); // Top sin
            }
            if (gstData['First name']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].FirstName[0]').setText(gstData['First name']); // first name
            }
            if (gstData['Last name']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].LastName[0]').setText(gstData['Last name']); // last name
            }
            if (gstData['Date of birth']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].DOB[0].DateYYYYMMDD_Comb[0]').setText(moment(gstData['Date of birth']).format('YYYYMMDD')); // dob
            }
            if (gstData['Phone numbers']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].TelephoneNumber1[0]').setText(gstData['Phone numbers']); // home
            }
            if (gstData['Work']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].TelephoneNumber2[0]').setText(gstData['Work']); // work
            }
            if (gstData['Ext']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].Extension[0]').setText(gstData['Ext']); // ext
            }
            if (gstData['Cell']) {
                form.getTextField('form1[0].Page1[0].Step1_sf[0].TelephoneNumber3[0]').setText(gstData['Cell']); // cell
            }
            if (gstData['Language']) {
                if (gstData['Language'] == 'English') {
                    form.getRadioGroup('form1[0].Page1[0].Step1_sf[0].Language_RadioButtonGroup[0]').select('1'); // 1 - English, 2 - Français ---- Your language of correspondence:
                }
            }


            //*--------- Step 2 – Your address ---------*//

            if (gstData['Apt. No. – Street No., Street name, PO Box, RR']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].MailingAddress1[0]').setText(gstData['Apt. No. – Street No., Street name, PO Box, RR']); // Apt. No. – Street No., Street name, PO Box, RR
            }
            if (gstData['City']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].City1[0]').setText(gstData['City']); // City
            }
            if (gstData['Postal code']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].PostalCode_Underlined[0]').setText(gstData['Postal code']); // Postal code
            }
            if (gstData['Province or territory']) {
                form.getDropdown('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].Prov_Territory[0]').select(gstData['Province or territory']); // Province or territory
            }
            if (gstData['Postal Code']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].PostalCode_Underlined[0]').setText(gstData['Postal Code']); // Postal code
            }
            if (gstData['Have you moved from a different province or territory within the last 12 months?']) {
                if (gstData['Have you moved from a different province or territory within the last 12 months?'] == 'Yes') {
                    form.getRadioGroup('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].IfYesRadioButtonGroup[0]').select('1'); // 1 - Yes, 2 - No ----  Have you moved from a different province or territory within the last 12 months?
                }
                if (gstData['Have you moved from a different province or territory within the last 12 months?'] == 'No') {
                    form.getRadioGroup('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].IfYesRadioButtonGroup[0]').select('2'); // 1 - Yes, 2 - No ----  Have you moved from a different province or territory within the last 12 months?
                }
            }
            if (gstData['enter the previous province or territory']) {
                form.getDropdown('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].ProvState_DropDown[0]').select(gstData['enter the previous province or territory']); //If yes, enter the previous province or territory and the date you moved:
            }
            if (gstData['enter the date you moved']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].MailingAddress_sf[0].Date1[0].DateYYYYMMDD_Comb[0]').setText(moment(gstData['enter the date you moved']).format('YYYYMMDD')); // Date
            }

            if (gstData['Same as mailing address']) {
                if (gstData['Same as mailing address'] == 'Yes') {
                    form.getCheckBox('form1[0].Page1[0].Step2_sf[0].HomeAddress_sf[0].SameAddress[0]').check(); // .uncheck(); for false ----  Same as mailing address
                }
                if (gstData['Same as mailing address'] == 'No') {
                    form.getCheckBox('form1[0].Page1[0].Step2_sf[0].HomeAddress_sf[0].SameAddress[0]').uncheck(); // .uncheck(); for false ----  Same as mailing address
                }
            }
            // Data not available for home address
            if (gstData['Residential Apt. No. – Street No., Street name, RR']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].HomeAddress_sf[0].HomeAddress[0]').setText('Residential Apt. No. – Street No., Street name, RR'); // Apt. No. – Street No., Street name, RR:
            }
            if (gstData['Residential City']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].HomeAddress_sf[0].City[0]').setText(gstData['Residential City']); // City
            }
            if (gstData['Residential Province or territory']) {
                form.getDropdown('form1[0].Page1[0].Step2_sf[0].HomeAddress_sf[0].Prov_Territory[0]').select(gstData['Residential Province or territory']); //  Province or territory:
            }
            if (gstData['Residential Postal code']) {
                form.getTextField('form1[0].Page1[0].Step2_sf[0].HomeAddress_sf[0].PostalCode_Underlined[0]').setText(gstData['Residential Postal code']); // Postal code
            }


            //*--------- Step 3 – Your marital status ---------*//
            if (gstData['marital status']) {
                if (gstData['marital status'] == 'Married') {
                    form.getCheckBox('form1[0].Page2[0].Step3_sf[0].MaritalStatus_List[0].Checkbox1[0].CheckBox[0]').check(); // .uncheck(); for false ----  Married
                }
                if (gstData['marital status'] == 'Living common-law') {
                    form.getCheckBox('form1[0].Page2[0].Step3_sf[0].MaritalStatus_List[0].Checkbox2[0].CheckBox[0]').check(); // .uncheck(); for false ----  Living common-law
                }
                if (gstData['marital status'] == 'Separated') {
                    form.getCheckBox('form1[0].Page2[0].Step3_sf[0].MaritalStatus_List[0].Checkbox3[0].CheckBox[0]').check(); // .uncheck(); for false ----  Separated
                }
                if (gstData['marital status'] == 'Divorced') {
                    form.getCheckBox('form1[0].Page2[0].Step3_sf[0].MaritalStatus_List[0].Checkbox4[0].CheckBox[0]').check(); // .uncheck(); for false ----  Divorced
                }
                if (gstData['marital status'] == 'Widowed') {
                    form.getCheckBox('form1[0].Page2[0].Step3_sf[0].MaritalStatus_List[0].Checkbox5[0].CheckBox[0]').check(); // .uncheck(); for false ----  Widowed
                }
                if (gstData['marital status'] == 'Single') {
                    form.getCheckBox('form1[0].Page2[0].Step3_sf[0].MaritalStatus_List[0].Checkbox6[0].CheckBox[0]').check(); // .uncheck(); for false ----  Single
                }
            }
            if (gstData['enter the date this marital status']) {
                form.getTextField('form1[0].Page2[0].Step3_sf[0].Date2[0].Date2YYYYMMDD_Comb[0]').setText(moment(gstData['enter the date this marital status']).format('YYYYMMDD')); //Enter the date the selected status began (leave it blank if you have always been single)
            }
            // if (gstData['']) {
            //     form.getRadioGroup('form1[0].Page2[0].Step3_sf[0].MSList2[0].MaritalStatusList2[0]').select('6'); // 1 - Married, 2 - Living common-law, 3 - Widowed, 4 - Divorced, 5 - Separated, 6 - Single ----  If your marital status has changed since you became a resident of Canada, select the box that applies to your new marital status and enter the date of this change:
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page2[0].Step3_sf[0].DateBegan[0].DateBegan_Comb[0]').setText('20200810'); // Since
            // }

            //-----------Spouse--------------
            if (gstData['enter your spouse SIN']) {
                form.getTextField('form1[0].Page2[0].Step3_sf[0].Step3_SIN_Comb_Top[0].Step3_SIN_Comb[0]').setText(); // Social insurance number (SIN)
            }
            if (gstData['Spouse First name']) {
                form.getTextField('form1[0].Page2[0].Step3_sf[0].FirstName[0]').setText(gstData['Spouse First name']); // First name
            }
            if (gstData['Spouse Last name']) {
                form.getTextField('form1[0].Page2[0].Step3_sf[0].LastName[0]').setText(gstData['Spouse Last name']); // Last name
            }
            if (gstData['Spouse Date of birth']) {
                form.getTextField('form1[0].Page2[0].Step3_sf[0].DOB[0].DateYYYYMMDD_Comb[0]').setText(moment(gstData['Spouse Date of birth']).format('YYYYMMDD')); // dob
            }
            if (gstData['spouse or common-law partner address is different']) {
                form.getTextField('form1[0].Page2[0].Step3_sf[0].SpouseAddress[0].MultilineTextField[0]').setText(gstData['spouse or common-law partner address is different']); // If your spouse or common-law partner's address is different from yours, enter it here; otherwise, their address will be updated to match the address indicated in Step 2.
            }



            //*--------- Step 4 – Your residency status ---------*//
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page2[0].Step4A_sf[0].Date3[0].Date3YYYYMMDD_Comb[0]').setText('20220118'); // date - user - Enter the date you, or your spouse or common-law partner, became a resident of Canada
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page2[0].Step4A_sf[0].Date4[0].Date4YYYYMMDD_Comb[0]').setText('20220118'); // date - spouse - Enter the date you, or your spouse or common-law partner, became a resident of Canada
            // }
            // if (gstData['']) {
            //     form.getDropdown('form1[0].Page3[0].Step4B_sf[0].Prov_DropDown1[0]').select('Canada'); // user - Enter the Canadian province or territory in which you, or your spouse or common-law partner, resided before you cut your residential ties with Canada.
            // }
            // if (gstData['']) {
            //     form.getDropdown('form1[0].Page3[0].Step4B_sf[0].Prov_DropDown2[0]').select('Canada'); // spouse - Enter the Canadian province or territory in which you, or your spouse or common-law partner, resided before you cut your residential ties with Canada.
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step4B_sf[0].Date5[0].Date5YYYYMMDD_Comb[0]').setText('20230428'); // date - user - Enter the date you, or your spouse or common-law partner, cut your residential ties with Canada (became a non-resident)
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step4B_sf[0].Date6[0].Date6YYYYMMDD_Comb[0]').setText('20230428'); // date - spouse - Enter the date you, or your spouse or common-law partner, cut your residential ties with Canada (became a non-resident)
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step4B_sf[0].Date7[0].Date7YYYYMMDD_Comb[0]').setText('20210507'); // date - user - Enter the date you, or your spouse or common-law partner, re-established your residential ties with Canada (became a resident again)
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step4B_sf[0].Date8[0].Date8YYYYMMDD_Comb[0]').setText('20210407'); // date - spouse - Enter the date you, or your spouse or common-law partner, re-established your residential ties with Canada (became a resident again)
            // }


            //*--------- Step 5 – Information about the child(ren) ---------*// 
            // Child - 1 
            if (gstData['Child 1: First name']) {
                form.getTextField('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].FirstName[0]').setText(gstData['Child 1: First name']); // first name ----- Child
            }
            if (gstData['Child 1: Last name']) {
                form.getTextField('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].LastName[0]').setText(gstData['Child 1: Last name']); // last name ----- Child
            }
            if (gstData['Child 1 Gender']) {
                if (gstData['Child 1 Gender'] == 'Male') {
                    form.getRadioGroup('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].Gender_RadioButtonGroup[0]').select('2'); // 1 - Female, 2 - Male ----  Gender:
                }
                if (gstData['Child 1 Gender'] == 'Female') {
                    form.getRadioGroup('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].Gender_RadioButtonGroup[0]').select('1'); // 1 - Female, 2 - Male ----  Gender:
                }
            }
            if (gstData['Child 1: Date of birth']) {
                form.getTextField('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].DOB[0].DateYYYYMMDD_Comb[0]').setText(moment(gstData['Child 1: Date of birth']).format('YYYYMMDD')); // dob
            }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].City[0]').setText('Mumbai'); // city of bith
            // }
            // if (gstData['']) {
            //     form.getDropdown('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].ProvState_DropDown[0]').select('Mumbai, India'); //  Province or territory of birth
            // }
            // if (gstData['']) {
            //     form.getRadioGroup('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].FullCust_RadioButtonGroup[0]').select('1'); // 1 - Yes, 2 - No ----  Does the child live with you more than 60% of the time?
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].Date1[0].DateYYYYMMDD_Comb[1]').setText('20200105'); // If yes, enter the date the child started living with you more than 60% of the time. If the child has lived with you since birth, tick the box:
            // }
            // if (gstData['']) {
            //     form.getCheckBox('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].SinceBirth[0]').check(); // .uncheck(); for uncheck ----  Since birth
            // }
            // if (gstData['']) {
            //     form.getCheckBox('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].Shared1[0]').check(); // .uncheck(); for uncheck ----  40% to 60% of the time, and the child lives the rest of the time with another individual at a different address
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].Date3[0].DateYYYYMMDD_Comb[2]').setText('20190504'); // If you select this box, the CRA considers you to be in a shared custody situation for this child. Enter the date the child started living with you 40% to 60% of the time:
            // }
            // if (gstData['']) {
            //     form.getCheckBox('form1[0].Page3[0].Step5_Child1_sf[0].#subform[0].Shared1[1]').check(); // .uncheck(); for uncheck ----  Less than 40% of the time (for example, the child lives with you every second weekend)
            // }
            // Child - 2

            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Child2[0].FirstName[0]').setText('Madhav'); // first name ----- Child
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Child2[0].LastName[0]').setText('Sethupathi'); // last name ----- Child
            // }
            if (gstData['Child 2 Gender']) {
                if (gstData['Child 2 Gender'] == 'Male') {
                    form.getRadioGroup('form1[0].Page4[0].Child2[0].Gender_RadioButtonGroup[0]').select('2'); // 1 - Female, 2 - Male ----  Gender:
                }
                if (gstData['Child 2 Gender'] == 'Female') {
                    form.getRadioGroup('form1[0].Page4[0].Child2[0].Gender_RadioButtonGroup[0]').select('1'); // 1 - Female, 2 - Male ----  Gender:
                }
            }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Child2[0].DOB[0].DateYYYYMMDD_Comb[0]').setText('20170405'); // dob
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Child2[0].City[0]').setText('Mumbai'); // city of bith
            // }
            // if (gstData['']) {
            //     form.getDropdown('form1[0].Page4[0].Child2[0].ProvState_DropDown[0]').select('Mumbai, India'); //  Province or territory of birth
            // }
            // if (gstData['']) {
            //     form.getRadioGroup('form1[0].Page4[0].Child2[0].FullCust_RadioButtonGroup[0]').select('1'); // 1 - Yes, 2 - No ----  Does the child live with you more than 60% of the time?
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Child2[0].Date9[0].Date9YYYYMMDD_Comb[0]').setText('20200105'); // If yes, enter the date the child started living with you more than 60% of the time. If the child has lived with you since birth, tick the box:
            // }
            // if (gstData['']) {
            //     form.getCheckBox('form1[0].Page4[0].Child2[0].SinceBirth[0]').check(); // .uncheck(); for uncheck ----  Since birth
            // }
            // if (gstData['']) {
            //     form.getCheckBox('form1[0].Page4[0].Child2[0].Shared2[0]').check(); // .uncheck(); for uncheck ----  40% to 60% of the time, and the child lives the rest of the time with another individual at a different address
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Child2[0].Date10[0].Date10YYYYMMDD_Comb[0]').setText('20190504'); // If you select this box, the CRA considers you to be in a shared custody situation for this child. Enter the date the child started living with you 40% to 60% of the time:
            // }
            // if (gstData['']) {
            //     form.getCheckBox('form1[0].Page4[0].Child2[0].Shared2[1]').check(); // .uncheck(); for uncheck ----  Less than 40% of the time (for example, the child lives with you every second weekend)
            // }



            //*--------- Step 6 – Your income ---------*//
            // A – The year you became a resident of Canada
            if (gstData['A-Enter the year you became a resident of Canada']) {
                form.getTextField('form1[0].Page4[0].Step6_sf[0].Step6_A[0].A_Year[0].S1YrComb[0]').setText(gstData['A-Enter the year you became a resident of Canada']); // Enter the year you became a resident of Canada
            }
            if (gstData['A-Enter the income earned from January 1 of the year you entered above to the date you each became a resident']) {
                form.getTextField('form1[0].Page4[0].Step6_sf[0].Step6_A[0].YouIncome[0]').setText(gstData['A-Enter the income earned from January 1 of the year you entered above to the date you each became a resident']); // your income
            }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page4[0].Step6_sf[0].Step6_A[0].PrtnrIncome[0]').setText('1500000'); // spouse - partner income
            // }

            // B – One year before you became a resident of Canada
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page5[0].Step6[0].Step6_B[0].B_Year[0].S2YrComb[0]').setText('2020'); // Enter the year that is one year before you became a resident of Canada.
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page5[0].Step6[0].Step6_B[0].YouIncome[0]').setText('1700000'); // your income
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page5[0].Step6[0].Step6_B[0].PrtnrIncome[0]').setText('1900000'); // spouse - partner income
            // }

            // C – Two years before you became a resident of Canada
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page5[0].Step6[0].Step6_C[0].C_Year[0].S3YrComb[0]').setText('2021'); // Enter the year that is two years before you became a resident of Canada .
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page5[0].Step6[0].Step6_C[0].YouIncome[0]').setText('2600000'); // your income
            // }
            // if (gstData['']) {
            //     form.getTextField('form1[0].Page5[0].Step6[0].Step6_C[0].PrtnrIncome[0]').setText('2500000'); // spouse - partner income
            // }



            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            return blob;
        } catch (error) { }
    }

    const submitGstPdf = async (gstPdf) => {
        try {
            const formData = new FormData();
            formData.append('organizer_id', id);
            formData.append('file', new File([gstPdf], 'filled_gst_form.pdf', { type: 'application/pdf' }));
            formData.append('type', 'gst');
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await SubmitOrganizerPdfApi(formData, apiHeader);
            if (data?.status) {
                toast.success(data?.message);
                navigate('/organizer');
            }
            navigate('/organizer');
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            navigate('/organizer');
        }
    }

    const pdfChecker = async () => {

        let authorizationPdf = await fillAuthorizationPdf();
        let gstPdf = await fillGSTPdf();

        try {
            setLoadingText('Sending signature Request...');
            const formData = new FormData();
            formData.append('organizer_id', id);
            formData.append('file', new File([authorizationPdf], 'filled_form.pdf', { type: 'application/pdf' }));
            formData.append('type', 'authorization');
            let apiHeader = { headers: { Accept: "application/json", "Content-Type": "multipart/form-data" } };
            const { data } = await SubmitOrganizerPdfApi(formData, apiHeader);
            if (data?.status) {
                submitGstPdf(gstPdf);
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            navigate('/organizer');
        }
    }

    useEffect(() => {
        pdfChecker();
    }, [])

    return (
        <div className='flex flex-col items-center gap-2 mt-[20%]'>
            <Spin />
            <p className='text-secondaryLight1'>{loadingText}</p>
        </div>
    )
}