import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GoogleLoginApi } from '../../../api/request/auth.js';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUserDetails, setloggedIn } from "../../../store/Slices/userSlice.js";
import { setCookies } from "../../../services/Cookies.js";
import { useNavigate } from 'react-router-dom';

const GoogleLoginComponent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSuccess = async (response) => {
        try {
            let params = { google_token: response.credential }
            const { data } = await GoogleLoginApi(params);
            if (data?.status) {
                dispatch(setloggedIn(true));
                dispatch(setUserDetails(data?.data));
                setCookies('token', data?.token);
                toast.success(data?.message);
                navigate('/dashboard');
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
        }

    };

    const handleFailure = (error) => {
        console.error('Login Failed:', error);
    };

    return (
        <GoogleOAuthProvider clientId="540073057360-g4dmcsj20donkht3gpbr4upcvkf20u74.apps.googleusercontent.com" >
            <div className='me-4'>
                <GoogleLogin
                    allowed_parent_origin=""
                    onSuccess={handleSuccess}
                    onError={handleFailure}
                    theme='outline'
                    useOneTap
                    redirectUri="http://client-login.taxtribe.ca"
                    cookiePolicy={'single_host_origin'}

                >
                    <button type="button" className='bg-primaryLight rounded-lg flex items-center justify-center py-2 sm:w-1/3 w-full me-5'> <img src='/assets/icons/Google.svg' alt='icon' className='me-2' />Google</button>

                </GoogleLogin>
            </div>
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginComponent;
